<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" topClass="topcolor-orange">
      <c-table
        ref="riskTable"
        title="위험성 평가 목록"
        :columns="grid.columns"
        :data="tabParam.riskAssessList"
        :filtering="false"
        :isExcelDown="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        :editable="editable"
        rowKey="ramRiskAssessmentPlanId"
        selection="multiple"
        @linkClick="linkClick"
      >
        <template slot="table-button">
          <q-btn-group outline >
              <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addRisk" />
              <c-btn v-if="editable && !disabled && tabParam.riskAssessList.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeRisk" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'accident-risk',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        vendorFlag: false,
        ramTechniqueCd: '',
        iimAccidentId: '',
      }),
    },
    tabParam: {
      type: Object,
      default: () => ({
        plantCd: '',
        iimAccidentId: '',
        accidentStatusCd: '',
        riskAssessList: [],
      }),
    },
  },
  data() { 
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sotrable: true
          },
          {
            name: "ramTechniqueName",
            field: 'ramTechniqueName',
            label: '평가분류',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'assessmentName',
            field: 'assessmentName',
            label: '평가명',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'assessmentDate',
            field: 'assessmentDate',
            label: '평가기간',
            align: 'center',
            style: 'width:150px',
            sortable: true
          },
          {
            name: 'ramAssessTypeName',
            field: 'ramAssessTypeName',
            label: '평가구분',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            label: '작성자',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
        height: '280px'
      }, 
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      postDelUrl: '',
      saveType: 'POST',
      isSave: false,
      linkRow: null,
      getUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isRamTechniqueCd() {
      return Boolean(this.popupParam.ramTechniqueCd)
    },
    disabled() {
      return this.editable && Boolean(this.tabParam.accidentStatusCd) && this.tabParam.accidentStatusCd === 'ISPC000005'
    },
    paramInfo() {
        return {
          ramRiskAssessmentPlanId: this.linkRow.ramRiskAssessmentPlanId,
          ramStepCd: this.linkRow.ramStepCd,
          vendorFlag: (this.linkRow.vendorFlag > 0),
        }
      },
      // popupOptionInfo() {
      //   return this.$_.find([
      //     {
      //       ramTechniqueCd: 'RT00000001',
      //       title: 'HAZOP 상세',
      //       param: this.paramInfo,
      //       target: () => import(`${'@/pages/ram/hazop/hazopDetail.vue'}`),
      //     },
      //     {
      //       ramTechniqueCd: 'RT00000005',
      //       title: 'K-PSR 상세',
      //       param: this.paramInfo,
      //       target: () => import(`${'@/pages/ram/kpsr/kpsrDetail.vue'}`),
      //     },
      //     {
      //       ramTechniqueCd: 'RT00000010',
      //       title: 'CHECKLIST 상세',
      //       param: this.paramInfo,
      //       target: () => import(`${'@/pages/ram/checklist/checkListDetail.vue'}`),
      //     },
      //     {
      //       ramTechniqueCd: 'RT00000015',
      //       title: 'JSA 상세',
      //       param: this.paramInfo,
      //       target: () => import(`${'@/pages/ram/jsa/jsaDetail.vue'}`),
      //     },
      //     {
      //       ramTechniqueCd: 'RT00000020',
      //       title: 'KRAS 상세',
      //       param: this.paramInfo,
      //       target: () => import(`${'@/pages/ram/kras/krasDetail.vue'}`),
      //     },
      //     {
      //       ramTechniqueCd: 'RT00000025',
      //       title: '4M 상세',
      //       param: this.paramInfo,
      //       target: () => import(`${'@/pages/ram/4m/4mDetail.vue'}`),
      //     },
      //     {
      //       ramTechniqueCd: 'RT00000030',
      //       title: 'CHARM 상세',
      //       param: this.paramInfo,
      //       target: () => import(`${'@/pages/ram/charm/charmDetail.vue'}`),
      //     },
      //   ], { ramTechniqueCd: this.linkRow.ramTechniqueCd })
      // },
    },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.iim.accident.process.get.url;
      // url setting
      this.postDelUrl = transactionConfig.ram.relateAccident.url;
      // code setting
      // list setting
      this.getDetail()
    },
    getDetail() {
      if (this.tabParam.iimAccidentId) {
        this.$http.url = this.$format(this.getUrl, this.tabParam.iimAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.tabParam.riskAssessList = this.$_.clone(_result.data.riskAssessList);
        },);
      }
    },
    /* eslint-disable no-unused-vars */ 
    addRisk() {
      this.popupOptions.title = '위험성 평가 검색'; 
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/iim/processAccidentRiskPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRiskPopup;
    },
    closeRiskPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveRisks = []
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.tabParam.riskAssessList, {
            ramRiskAssessmentPlanId: _item.ramRiskAssessmentPlanId
          });
          if (index === -1) {
            saveRisks.push({
              iimAccidentId: this.tabParam.iimAccidentId,
              ramRiskAssessmentPlanId: _item.ramRiskAssessmentPlanId,
              plantName: _item.plantName,
              ramTechniqueName: _item.ramTechniqueName,
              assessmentName: _item.assessmentName,
              assessmentDate:_item.assessmentDate,
              ramAssessTypeName:_item.ramAssessTypeName,
              regUserName:_item.regUserName,
              reviewUserName:_item.reviewUserName,
              approvalUserName:_item.approvalUserName,
              editFlag: 'C',
            })
          }
        })
        this.$http.url = this.postDelUrl;
        this.$http.type = 'POST';
        this.$http.param = saveRisks;
        this.$http.request((_result) => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getDetail();
        },);
      }
    },
    removeRisk() {
      let selectData = this.$refs['riskTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.postDelUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
              this.$_.forEach(selectData, _item => {
                this.tabParam.riskAssessList = this.$_.reject(this.tabParam.riskAssessList, _item)
                this.$refs['riskTable'].selected = [];
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              },);
            })
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    linkClick(row) {
      console.log(row)
      // this.$set(this.$data, 'linkRow', row)
      // this.$_.extend(this.popupOptions, this.popupOptionInfo)
      this.popupOptions.title = '위험성평가 상세'; // 위험성평가 상세
      this.popupOptions.param = {
        ramRiskAssessmentPlanId: row ? row.ramRiskAssessmentPlanId : '',
        ramStepCd: row ? row.ramStepCd : 'RRS0000001',
        vendorFlag: row ? (row.vendorFlag > 0) : false,
        ramTechniqueCd: row.ramTechniqueCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/riskDetail.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
